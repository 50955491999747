import { memo } from 'react';

const Menu = () => (
  <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 0H0V2H24V0ZM24 6H0V8H24V6ZM0 12H24V14H0V12Z"
      fill="#363232"
    />
  </svg>
);

export const MenuIcon = memo(Menu);
