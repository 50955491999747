import { useState } from 'react';

import NextLink from 'next/link';

import { Nav, Navbar } from '@olist/united';

import { ArrowDownIcon } from 'modules/icons/ArrowDown';
import { CloseIcon } from 'modules/icons/Close';
import { ComputerIcon } from 'modules/icons/Computer';
import { DirectIcon } from 'modules/icons/Direct';
import { IntegrationsIcon } from 'modules/icons/Integrations';
import { MenuIcon } from 'modules/icons/Menu';
import { MigrationsIcon } from 'modules/icons/Migrations';
import { useParams } from 'services/params';

import * as S from './Header.style';
import * as T from './Header.types';

export const Header: React.FC<T.HeaderCollection> = ({ menus }) => {
  const [openedMenuMobile, setOpenedMenuMobile] = useState(false);
  const [openedWhy, setOpenedWhy] = useState(false);

  const params = useParams();

  return (
    <S.Header>
      <S.Container>
        <Navbar>
          <Navbar.Brand>
            <NextLink href={`/${params}`} passHref>
              <a href="replace">
                <img
                  src="/images/olist.svg"
                  alt="Olist: Soluções de Vendas Online e Serviços de E-commerce"
                  title="Olist: Soluções de Vendas Online e Serviços de E-commerce"
                  height="40px"
                  width="100px"
                />
              </a>
            </NextLink>
          </Navbar.Brand>
          <Nav className={openedMenuMobile ? 'open' : ''}>
            <Nav.Link onClick={() => setOpenedWhy(!openedWhy)}>
              por que a <strong>Olist Vnda?</strong>
              <ArrowDownIcon />
              <S.Submenu className={openedWhy ? 'open' : ''}>
                <div className="column">
                  <span>PRINCIPAIS DIFERENCIAIS</span>
                  <Nav.Link href={`/plataforma${params}`}>
                    <ComputerIcon />
                    plataforma
                    <small>a única plataforma de e-commerce omnichannel de verdade</small>
                  </Nav.Link>
                  <Nav.Link href={`/integracoes${params}`}>
                    <IntegrationsIcon />
                    integrações
                    <small>mais de 200 integrações disponíveis para sua loja</small>
                  </Nav.Link>
                  <Nav.Link href={`/direct${params}`}>
                    <DirectIcon />
                    direct
                    <small>integre o estoque de todos os seus canais de venda</small>
                  </Nav.Link>
                  <S.Hr />
                  <Nav.Link href={`/migracao-de-plataforma${params}`}>
                    <MigrationsIcon />
                    migre para a Olist Vnda
                    <small>sua operação pronta em menos de 30 dias, com segurança e eficácia</small>
                  </Nav.Link>
                  <S.Hr />
                  <Nav.Link href="https://developers.vnda.com.br/" target="_blank">
                    developers
                  </Nav.Link>
                  <Nav.Link href="http://ajuda.vnda.com.br/pt-BR/" target="_blank">
                    ajuda
                  </Nav.Link>
                </div>
                <div className="column">
                  <span>sobre nós</span>
                  <Nav.Link href="/">
                    <strong>Olist Vnda</strong> faz parte do ecossistema de soluções do
                    <strong> olist</strong>
                    <small>conheça outros produtos do grupo:</small>
                  </Nav.Link>
                  <Nav.Link href="https://www.tiny.com.br/" target="_blank">
                    tiny
                    <small>gestão do negócio</small>
                  </Nav.Link>
                  <Nav.Link href="https://olist.com/pt-br/" target="_blank">
                    olist store
                    <small>vender em marketplaces</small>
                  </Nav.Link>
                  <Nav.Link href="https://olistpax.com.br/" target="_blank">
                    olist pax
                    <small>logística otimizada</small>
                  </Nav.Link>
                </div>
              </S.Submenu>
            </Nav.Link>
            {menus.map((menu) => {
              return (
                <Nav.Link
                  key={menu.title}
                  target={menu.extern ? '_blank' : ''}
                  href={menu.link + params}
                >
                  {menu.title}
                </Nav.Link>
              );
            })}
            <Nav.Link
              className="login-menu"
              href="https://login.vnda.com.br/?utm_source=site-vnda&utm_medium=cpc&utm_campaign=site-vnda&utm_content=login-link"
            >
              login
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link className="login">
              <S.Link>
                <Nav.Link href="https://login.vnda.com.br/?utm_source=site-vnda&utm_medium=cpc&utm_campaign=site-vnda&utm_content=login-link">
                  login
                </Nav.Link>
              </S.Link>
            </Nav.Link>
            <Nav.Link>
              <S.Link>
                <Nav.Link href="#contato">fale com um especialista</Nav.Link>
              </S.Link>
            </Nav.Link>

            <Nav.Link className="open-menu" onClick={() => setOpenedMenuMobile(!openedMenuMobile)}>
              {!openedMenuMobile && <MenuIcon />}
              {openedMenuMobile && <CloseIcon />}
            </Nav.Link>
          </Nav>
        </Navbar>
      </S.Container>
    </S.Header>
  );
};
