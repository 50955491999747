import { memo } from 'react';

const Computer = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.8732 12H1.12676C0.507042 12 0 11.4906 0 10.8679V1.13208C0 0.509434 0.507042 0 1.12676 0H14.8732C15.493 0 16 0.509434 16 1.13208V10.8679C16 11.4906 15.493 12 14.8732 12ZM1.12676 1.13208V10.8679H14.8732V1.13208H1.12676Z"
      fill="#0c29d0"
    />
    <path
      d="M7.42884 8.14719C7.27087 8.14719 7.11897 8.08706 6.99746 7.97282L5.32054 6.31329C5.08358 6.07879 5.08358 5.69999 5.32054 5.46549C5.5575 5.23099 5.94027 5.23099 6.17723 5.46549L7.42276 6.69811L9.8227 4.32306C10.0597 4.08856 10.4424 4.08856 10.6794 4.32306C10.9163 4.55756 10.9163 4.93636 10.6794 5.17086L7.85415 7.9668C7.73263 8.08706 7.58074 8.14117 7.42276 8.14117L7.42884 8.14719Z"
      fill="#0c29d0"
    />
    <path
      d="M10.8394 14.8571H5.16423C4.98097 14.8571 4.80953 14.7748 4.69721 14.6336C4.58489 14.4924 4.54351 14.3041 4.5908 14.1276L5.3002 11.3036C5.37705 10.9859 5.7022 10.7976 6.01551 10.8741C6.33474 10.9506 6.52392 11.2742 6.44707 11.586L5.92093 13.6805H10.0827L9.5566 11.586C9.47975 11.2683 9.66893 10.9506 9.98816 10.8741C10.3074 10.7976 10.6266 10.9859 10.7035 11.3036L11.4129 14.1276C11.4543 14.3041 11.4129 14.4924 11.3065 14.6336C11.2 14.7748 11.0227 14.8571 10.8394 14.8571Z"
      fill="#0c29d0"
    />
  </svg>
);

export const ComputerIcon = memo(Computer);
