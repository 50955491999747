import { memo } from 'react';

const Migrations = () => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4523 8.47066L12.1212 7.19819L14.2793 5.13508L0.500214 5.13508C0.224072 5.13508 0.000213935 4.91122 0.000213959 4.63508L0.000214029 3.83553C0.000214053 3.55939 0.22407 3.33553 0.500214 3.33553L14.2792 3.33554L12.1212 1.27248L13.4523 1.10763e-05L17.5045 3.87392C17.7104 4.07084 17.7104 4.39983 17.5045 4.59675L13.4523 8.47066ZM4.43001 7.52935L5.76105 8.80182L3.60298 10.8649H17.3821C17.6582 10.8649 17.8821 11.0888 17.8821 11.3649V12.1645C17.8821 12.4406 17.6582 12.6645 17.3821 12.6645L3.60304 12.6645L5.76105 14.7275L4.43001 16L0.377809 12.1261C0.171826 11.9292 0.171826 11.6002 0.377808 11.4033L4.43001 7.52935Z"
      fill="#0c29d0"
    />
  </svg>
);

export const MigrationsIcon = memo(Migrations);
