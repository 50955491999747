import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { Flex } from '@olist/united';

export const Header = styled.header`
  background-color: #ffffff;
  border-bottom: 1px solid ${themeGet('olist.color.neutral.softer')};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 90;
  > div {
    padding: 0 calc(80px - 10px);
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.medium}) {
            padding: 0 calc(48px - 10px);
          }
          @media (max-width: ${theme.breakpoints.small}) {
            padding: 0 calc(24px - 10px);
          }
          @media (max-width: ${theme.breakpoints.extraSmall}) {
            padding: 0 calc(16px - 10px);
          }
        `
      );
    }}
  }
`;

export const Container = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 calc(80px - 10px);
  > div {
    justify-content: space-between;
    padding: 0;
    width: 100%;
    > div {
      flex: 1;
      &:nth-child(1) {
        img {
          object-fit: cover;
        }
      }
      &:nth-child(2) {
        flex: 10;
        ${({ theme }) => {
          return (
            theme &&
            css`
              @media (max-width: ${theme.breakpoints.medium}) {
                position: fixed;
                top: 63px;
                background: white;
                flex-direction: column;
                width: 100%;
                align-items: flex-start;
                height: calc(100vh - 63px);
                overflow: auto;
                left: 0;
                opacity: 0;
                z-index: -9;
                visibility: hidden;
                transition: 0.3s;
                > a {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  flex-wrap: wrap;
                  strong {
                    margin-left: 10px;
                  }
                }
                &.open {
                  opacity: 1;
                  z-index: 9;
                  visibility: visible;
                }
              }
            `
          );
        }}
        > a {
          padding: 22px 10px;
          strong {
            font-weight: 600;
          }
          &.login-menu {
            ${({ theme }) => {
              return (
                theme &&
                css`
                  @media (min-width: ${theme.breakpoints.medium}) {
                    display: none;
                  }
                `
              );
            }}
          }
          ${({ theme }) => {
            return (
              theme &&
              css`
                @media (max-width: ${theme.breakpoints.medium}) {
                  padding: 12px 10px;
                }
              `
            );
          }}
        }
      }
      &:nth-child(3) {
        ${({ theme }) => {
          return (
            theme &&
            css`
              @media (max-width: ${theme.breakpoints.medium}) {
                justify-content: flex-end;
              }
            `
          );
        }}
      }
      > a {
        color: #363232;
        font-weight: 400;
        padding: 10px;
        position: relative;
        transition: 0.3s;
        &:visited {
          color: #363232 !important;
        }
        &:hover {
          color: #0c29d0 !important;
        }
        &.login {
          ${({ theme }) => {
            return (
              theme &&
              css`
                @media (max-width: ${theme.breakpoints.medium}) {
                  display: none;
                }
              `
            );
          }}
          a {
            background: transparent;
            border-color: #e5e5f2;
            color: #474242;
            &:visited {
              color: #474242 !important;
            }
            &:hover {
              color: #ffffff !important;
            }
          }
        }
        &.open-menu {
          display: none;
          padding: 0;
          padding-right: 12px;
          transition: none;
          &:hover {
            svg {
              transition: none;
            }
          }
          ${({ theme }) => {
            return (
              theme &&
              css`
                @media (max-width: ${theme.breakpoints.medium}) {
                  display: flex;
                }
              `
            );
          }}
          svg {
            height: 24px;
            width: 24px;
          }
        }
        > div {
          opacity: 0;
          visibility: hidden;
          z-index: -9;
          ${({ theme }) => {
            return (
              theme &&
              css`
                @media (max-width: ${theme.breakpoints.medium}) {
                  opacity: 1;
                  visibility: visible;
                  z-index: 9;
                }
              `
            );
          }}
        }
        > svg {
          height: 10px;
          margin-left: 5px;
          transform: rotate(180deg);
          transition: 0.3s;
          width: 10px;
        }
        &:hover {
          ${({ theme }) => {
            return (
              theme &&
              css`
                @media (min-width: ${theme.breakpoints.medium}) {
                  > div {
                    opacity: 1;
                    visibility: visible;
                    z-index: 9;
                  }
                }
              `
            );
          }}
          > svg {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
`;

export const Link = styled.div`
  opacity: 1 !important;
  position: relative;
  visibility: visible !important;
  z-index: 9 !important;
  > a {
    align-items: center;
    background-color: #0c29d0;
    border: 1px solid #0c29d0;
    border-radius: ${themeGet('olist.borderRadius.pill')};
    color: #ffffff;
    display: flex;
    height: 40px;
    justify-content: center;
    padding: 0px 18px;
    text-decoration: none;
    transition: 0.3s;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.medium}) {
            font-size: 12px;
            padding: 0px 10px;
            width: 100px;
            white-space: initial;
            text-align: center;
            line-height: 12px;
          }
        `
      );
    }}

    &:visited {
      color: #ffffff !important;
    }

    &:hover {
      background-color: #0c29d0 !important;
      border: 1px solid #0c29d0 !important;
      box-shadow: none !important;
      color: #ffffff !important;
      opacity: 0.85;
    }
`;

export const Whatsapp = styled.a`
  align-items: center;
  color: #363232;
  display: flex;
  justify-content: center;
  path {
    transition: 0.3s;
  }
`;

export const Submenu = styled.div`
  background: #ffffff;
  border: 1px solid ${themeGet('olist.color.neutral.softer')};
  border-radius: 0px 0px 8px 8px;
  box-shadow: 2px 16px 24px rgba(71, 66, 66, 0.06);
  display: flex;
  left: -25px;
  position: absolute;
  top: 100%;
  transition: 0.3s;
  width: 680px;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.medium}) {
          position: relative;
          left: 0;
          width: 100%;
          border-radius: 0;
          box-shadow: 0 0 0 rgba(71, 66, 66, 0);
          top: 0;
          flex-direction: column;
          border: 0px solid ${themeGet('olist.color.neutral.softer')};
          height: 0;
          overflow: hidden;
          &.open {
            height: calc(100vh - 63px);
            opacity: 9;
            visibility: visible;
            z-index: 9;
          }
        }
      `
    );
  }}
  .column {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 40px 34px;
    width: 50%;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.medium}) {
            width: 100%;
            padding: 24px;
          }
        `
      );
    }}
    &:nth-child(2) {
      background: ${themeGet('olist.color.neutral.softer')};
      a {
        padding-left: 0;
        &:first-of-type {
          font-weight: 400;
          font-size: 18px;
          pointer-events: none;
          strong {
            font-weight: 600;
          }
        }
      }
    }
    span {
      color: #363232;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      margin-bottom: 6px;
    }
    hr:nth-child(5) {
      margin-bottom: 12px;
    }
    a {
      color: #363232;
      line-height: 150%;
      padding-left: 32px;
      position: relative;
      transition: 0.3s;
      white-space: initial;
      &:nth-child(8),
      &:nth-child(9) {
        padding-left: 0;
      }
      &:visited {
        color: #363232;
      }
      &:hover {
        color: #0c29d0;
        svg {
          path {
            fill: #0c29d0 !important;
          }
        }
      }
      small {
        color: ${themeGet('olist.color.neutral.base')};
        display: block;
        font-weight: 300;
        font-size: 14px;
        line-height: 150%;
      }
      svg {
        left: 0;
        position: absolute;
        top: 13px;
        path {
          fill: #0c29d0 !important;
        }
      }
    }
  }
`;

export const Hr = styled.hr`
  border: 0;
  border-bottom: 1px solid;
  border-color: ${themeGet('olist.color.neutral.softer')};
  margin-bottom: 24px;
  width: 100%;
`;
