import { useMemo } from 'react';

import NextLink from 'next/link';

import { Nav, Navbar } from '@olist/united';

import { FacebookIcon } from 'modules/icons/Facebook';
import { InstagramIcon } from 'modules/icons/Instagram';
import { LinkedinIcon } from 'modules/icons/Linkedin';
import { useParams } from 'services/params';

import * as S from './Footer.style';
import * as T from './Footer.types';
import { Menu } from './Footer.types';
import { FormNewsletter } from './Form';

export const Footer: React.FC<T.FooterCollection> = ({ menus }) => {
  const params = useParams();

  const year = useMemo(() => new Date().getFullYear(), []);
  return (
    <S.Footer id="footer">
      <S.Container>
        <Navbar>
          <Navbar.Brand>
            <NextLink href="/" passHref>
              <a href="replace">
                <img
                  src="/images/olist.svg"
                  alt="Olist: Soluções de Vendas Online e Serviços de E-commerce"
                  title="Olist: Soluções de Vendas Online e Serviços de E-commerce"
                  height="40px"
                  width="100px"
                />
              </a>
            </NextLink>
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/vndaecommerce"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label=""
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.instagram.com/olist.oficial/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label=""
                >
                  <InstagramIcon />
                </a>
                <a
                  href="https://www.linkedin.com/company/vnda-ecommerce/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label=""
                >
                  <LinkedinIcon />
                </a>
              </li>
            </ul>
            <p>
              <strong>Porto Alegre - RS</strong>
              <br />
              Rua Germano Petersen Júnior, 508
              <br />
              4º Andar - Auxiliadora
            </p>
            <p>
              <strong>São Paulo - SP</strong>
              <br />
              Rua Gomes de Carvalho, 1356
              <br />
              7º andar - Vila Olímpia
            </p>
          </Navbar.Brand>
          <Nav>
            {menus['1'].map((menu: Menu) => {
              return (
                <Nav.Link
                  key={menu.title}
                  target={menu.externo ? '_blank' : ''}
                  href={menu.url + params}
                >
                  {menu.title}
                </Nav.Link>
              );
            })}
          </Nav>
          <Nav>
            {menus['2'].map((menu: Menu) => {
              return (
                <Nav.Link
                  key={menu.title}
                  target={menu.externo ? '_blank' : ''}
                  href={menu.url + params}
                >
                  {menu.title}
                </Nav.Link>
              );
            })}
          </Nav>
          <Nav>
            {menus['3'].map((menu: Menu) => {
              return (
                <Nav.Link
                  key={menu.title}
                  target={menu.externo ? '_blank' : ''}
                  href={menu.url + params}
                >
                  {menu.title}
                </Nav.Link>
              );
            })}
          </Nav>
        </Navbar>
      </S.Container>
      <S.Container>
        <S.Seals>
          <img src="/images/aws.png" alt="logo da vnda" height="65px" width="71px" />
          <img src="/images/teal.png" alt="logo da vnda" height="69px" width="101px" />
        </S.Seals>
        <S.Newsletter>
          <p>
            Receba conteúdos em primeira mão <br /> e aprenda como impulsionar o crescimento da sua
            loja
          </p>
          <FormNewsletter />
        </S.Newsletter>
      </S.Container>
      <S.Copy>© {year}, Olist Vnda - Tecnologia em E-commerce.</S.Copy>
    </S.Footer>
  );
};
