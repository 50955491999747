import { memo } from 'react';

const ArrowDown = () => (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.97483 2.01303L1.0074 6L-2.18466e-07 4.99793L4.97483 2.17457e-07L9.94966 4.97483L8.94403 5.97957L4.97483 2.01303Z"
      fill="currentColor"
    />
  </svg>
);

export const ArrowDownIcon = memo(ArrowDown);
