import styled, { css } from 'styled-components';

import { Flex } from '@olist/united';

export const Footer = styled.footer`
  border-top: 1px solid #f5f5f5;
  padding-bottom: 10px;
  padding-top: 64px;
`;

export const Container = styled(Flex)`
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 80px;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.medium}) {
          padding: 0 48px;
        }
        @media (max-width: ${theme.breakpoints.small}) {
          padding: 0 24px;
        }
        @media (max-width: ${theme.breakpoints.extraSmall}) {
          padding: 0 16px;
          flex-direction: column;
        }
      `
    );
  }}
  > div {
    display: flex;
    width: 100%;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.extraSmall}) {
            flex-direction: column;
          }
        `
      );
    }}
    > div {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      ${({ theme }) => {
        return (
          theme &&
          css`
            @media (max-width: ${theme.breakpoints.small}) {
              margin-top: 49px;
            }
          `
        );
      }}
      img {
        padding-bottom: 24px !important;
        object-fit: cover;
      }
      ul {
        display: flex;
        align-itens: center;
        justify-content: flex-start;
        padding-bottom: 32px;
        a {
          margin-right: 12px;
          &:hover {
            svg path {
              fill: #0c29d0 !important;
            }
          }
        }
        svg {
          width: 24px;
          height: 24px;
          path {
            transition: 0.3s;
          }
        }
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 16px;
        color: #363232;
        strong {
          font-weight: 700;
        }
      }
      a {
        color: #363232;
        font-weight: 400;
        margin-bottom: 8px;
        padding: 0;
        &:hover {
          color: #0c29d0 !important;
        }
      }
    }
  }
`;

export const Seals = styled.div`
  margin-top: 80px;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.small}) {
          width: 100%;
          flex-direction: row !important;
          margin-top: 48px;
        }
      `
    );
  }}
  img {
    padding-right: 10px !important;
  }
`;

export const Newsletter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  max-width: 434px;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.small}) {
          margin-top: 48px;
        }
      `
    );
  }}
  p {
    margin-bottom: 16px;
  }
  ._form_128 {
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.small}) {
            margin-top: 0;
          }
        `
      );
    }}
  }
  form {
    background-color: transparent !important;
    margin-top: 0 !important;
    padding: 0 !important;
    width: 100%;
    ._form-content {
      display: flex;
      justify-content: space-between !important;
      ${({ theme }) => {
        return (
          theme &&
          css`
            @media (max-width: ${theme.breakpoints.small}) {
              flex-direction: column !important;
            }
          `
        );
      }}
      ._form_element {
        width: calc(100% - 134px) !important;
        ${({ theme }) => {
          return (
            theme &&
            css`
              @media (max-width: ${theme.breakpoints.small}) {
                width: 100% !important;
              }
            `
          );
        }}
        ._form-label {
          display: none !important;
        }
        input {
          width: 100% !important;
          border: 1px solid #cfcfcf !important;
          border-radius: 32px !important;
          padding: 10px 20px !important;
          font-weight: 400 !important;
          font-size: 16px !important;
          letter-spacing: 0.02em !important;
          height: 50px !important;
        }
      }
      ._button-wrapper {
        width: 124px !important;
        button {
          font-size: 16px !important;
          width: 124px !important;
        }
      }
    }
  }
`;

export const Copy = styled.p`
  border-top: 1px solid #f5f5f5;
  display: block;
  font-size: 14px;
  margin-top: 32px;
  padding-top: 32px;
  text-align: center;
  width: 100%;
`;
