import { memo } from 'react';

const Direct = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.08667 6.09333H8.68C8.94 6.09333 9.14667 5.88667 9.14667 5.62667V3.80667H10.9667C11.2267 3.80667 11.4333 3.6 11.4333 3.34V2.74667C11.4333 2.48667 11.2267 2.28 10.9667 2.28H9.14667V0.466667C9.14 0.206667 8.93333 0 8.67333 0H8.08C7.82 0 7.61333 0.206667 7.61333 0.466667V2.28667H5.79333C5.53333 2.28667 5.32667 2.49333 5.32667 2.75333V3.34667C5.32667 3.60667 5.53333 3.81333 5.79333 3.81333H7.61333V5.63333C7.61333 5.89333 7.82 6.1 8.08 6.1L8.08667 6.09333ZM4.94 12.9933C3.80667 12.7267 2.82667 13.7333 3.10667 14.86C3.24 15.3867 3.67333 15.82 4.2 15.9533C5.32667 16.2267 6.33333 15.2133 6.04667 14.0867C5.91333 13.5533 5.47333 13.12 4.94 12.9933V12.9933ZM12.56 12.9933C11.4267 12.7267 10.4467 13.7333 10.7267 14.86C10.86 15.3867 11.2933 15.82 11.82 15.9533C12.9467 16.2267 13.9533 15.2133 13.6667 14.0867C13.5333 13.5533 13.0933 13.12 12.56 12.9933ZM4.7 10.4733L4.72 10.38L5.21333 9.48C5.33333 9.26667 5.55333 9.13333 5.8 9.13333H11.08C11.6533 9.13333 12.1533 8.82 12.4133 8.34667L15.1267 3.41333C15.2533 3.18667 15.1667 2.90667 14.94 2.78L14.02 2.27333H14.0133L13.1733 3.8L11.26 7.26667C11.14 7.48 10.92 7.61333 10.6733 7.61333H5.72L5.62 7.40667L3.91333 3.8L3.18667 2.27333L2.65333 1.13333C2.54667 0.9 2.30667 0.753333 2.04667 0.753333H0.466667C0.206667 0.76 0 0.973333 0 1.22667V1.82C0 2.08 0.206667 2.28667 0.466667 2.28667H1.31333C1.44 2.28667 1.56 2.36 1.61333 2.48L4.12 7.76667C4.21333 7.96 4.20667 8.18667 4.1 8.37333L3.23333 9.94C2.95333 10.4333 2.95333 11.1333 3.43333 11.7533C3.66667 12.0533 4.04 12.1933 4.42 12.1933C6.16 12.1933 11.62 12.1933 13.2467 12.1933C13.5067 12.1933 13.7133 11.9867 13.7133 11.7267V11.1333C13.7133 10.8733 13.5067 10.6667 13.2467 10.6667H4.89333C4.79333 10.6667 4.7 10.58 4.7 10.4733V10.4733Z"
      fill="#0c29d0"
    />
  </svg>
);

export const DirectIcon = memo(Direct);
