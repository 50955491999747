import { useRouter } from 'next/router';

export const useParams = () => {
  const router = useRouter();
  const params = router?.asPath;

  let newParams = '';
  if (params?.includes('?')) newParams = `?${params.split('?')[1]}`;

  return newParams;
};
