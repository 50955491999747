import { memo } from 'react';

const Linkedin = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0.25C18.623 0.25 24 5.62703 24 12.25C24 18.873 18.623 24.25 12 24.25C5.37703 24.25 0 18.873 0 12.25C0 5.62703 5.37703 0.25 12 0.25ZM8.24827 18.994V9.62266H5.13277V18.994H8.24827ZM19.4805 18.994V13.6199C19.4805 10.7414 17.9436 9.4023 15.8941 9.4023C14.2415 9.4023 13.5013 10.3112 13.0868 10.9495V9.62266H9.97209C10.0134 10.5019 9.97209 18.994 9.97209 18.994H13.0868V13.7604C13.0868 13.4802 13.1069 13.2002 13.1895 13C13.4142 12.4405 13.9271 11.861 14.7877 11.861C15.9143 11.861 16.3656 12.7207 16.3656 13.9799V18.994H19.4805ZM6.71156 5.10391C5.64562 5.10391 4.9492 5.80469 4.9492 6.7232C4.9492 7.62236 5.62444 8.34245 6.67031 8.34245H6.69042C7.77675 8.34245 8.45292 7.62236 8.45292 6.7232C8.43277 5.80469 7.7768 5.10391 6.71156 5.10391Z"
      fill="#807E7E"
    />
  </svg>
);

export const LinkedinIcon = memo(Linkedin);
