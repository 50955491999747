import { memo } from 'react';

const Integrations = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.34462 7.39077H1.04615C0.467692 7.39077 0 6.92308 0 6.34462V1.04615C0 0.467692 0.467692 0 1.04615 0H6.34462C6.92308 0 7.39077 0.467692 7.39077 1.04615V6.34462C7.39077 6.92308 6.92308 7.39077 6.34462 7.39077ZM1.23077 6.16H6.16V1.23077H1.23077V6.16Z"
      fill="#0c29d0"
    />
    <path
      d="M15.0768 7.39077H9.53221C9.02144 7.39077 8.60913 6.97846 8.60913 6.46769V0.923077C8.60913 0.412308 9.02144 0 9.53221 0H14.9537C15.5322 0 15.9999 0.467692 15.9999 1.04615V6.46769C15.9999 6.97846 15.5876 7.39077 15.0768 7.39077ZM9.8399 6.16H14.7691V1.23077H9.8399V6.16Z"
      fill="#0c29d0"
    />
    <path
      d="M6.34462 16H1.04615C0.467692 16 0 15.5323 0 14.9538V9.65538C0 9.07692 0.467692 8.60923 1.04615 8.60923H6.34462C6.92308 8.60923 7.39077 9.07692 7.39077 9.65538V14.9538C7.39077 15.5323 6.92308 16 6.34462 16V16ZM1.23077 14.7692H6.16V9.84H1.23077V14.7692Z"
      fill="#0c29d0"
    />
    <path
      d="M14.9539 16H9.66163C9.08317 16 8.61548 15.5323 8.61548 14.9538V9.66154C8.61548 9.08308 9.08317 8.61538 9.66163 8.61538H14.9539C15.5324 8.61538 16.0001 9.08308 16.0001 9.66154V14.9538C16.0001 15.5323 15.5324 16 14.9539 16V16ZM9.84625 14.7692H14.7693V9.84615H9.84625V14.7692Z"
      fill="#0c29d0"
    />
  </svg>
);

export const IntegrationsIcon = memo(Integrations);
